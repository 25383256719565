(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/rich-text/assets/javascripts/viewer.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/rich-text/assets/javascripts/viewer.js');

'use strict';

const {
  Editor,
  EditorState
} = Draft;
const {
  ReactIconButton,
  ReactIcon
} = svs.ui;
const {
  useState
} = React;
const Viewer = _ref => {
  let {
    richDescription,
    withExpander
  } = _ref;
  const [isExpanded, setIsExpanded] = useState(false);
  const ViewerEditor = () => React.createElement(Editor, {
    editorState: richDescription,
    readOnly: true
  });
  if (!withExpander || richDescription.getCurrentContent().getPlainText().length <= 450) {
    return React.createElement(ViewerEditor, null);
  }
  const onToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return React.createElement("div", null, React.createElement("div", {
    className: "rich-text-viewer ".concat(isExpanded ? 'rich-text-viewer-expanded' : 'rich-text-viewer-compact')
  }, React.createElement(ViewerEditor, null)), React.createElement(ReactIconButton, {
    className: "rich-text-viewer-expander-arrow qa-text-expander-arrow",
    onClick: onToggleExpand
  }, React.createElement("div", {
    style: {
      transition: 'transform .15s cubic-bezier(0.5, 1, 0.89, 1)',
      transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'
    }
  }, React.createElement(ReactIcon, {
    icon: "menu-down",
    size: "200"
  }))));
};
Viewer.defaultProps = {
  richDescription: EditorState.createEmpty(),
  withExpander: false
};
setGlobal('svs.components.marketplaceRetailAdmin.richText.Viewer', Viewer);

 })(window);