(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/rich-text/assets/javascripts/helpers/parse-editor-state.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/rich-text/assets/javascripts/helpers/parse-editor-state.js');

'use strict';

function parseEditorState(editorJson) {
  if (editorJson instanceof Draft.EditorState) {
    return editorJson;
  }
  try {
    return Draft.EditorState.createWithContent(Draft.convertFromRaw(JSON.parse(editorJson)));
  } catch (e) {
    return Draft.EditorState.createEmpty();
  }
}
setGlobal('svs.components.marketplaceRetailAdmin.richText.helpers.parseEditorState', parseEditorState);

 })(window);