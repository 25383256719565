(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/rich-text/assets/javascripts/helpers/editor-to-json.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/rich-text/assets/javascripts/helpers/editor-to-json.js');

'use strict';

function editorToJson(editor) {
  try {
    return JSON.stringify(Draft.convertToRaw(editor.getCurrentContent()));
  } catch (e) {
    return '';
  }
}
setGlobal('svs.components.marketplaceRetailAdmin.richText.helpers.editorToJson', editorToJson);

 })(window);